export const regExpressions = {
  cardExpDate: /^\d{2}\s*\/\s*\d{2}$/,
  digitsOrPlus: /[+\d]/g,
  dotAtStart: /^\./,
  doubleAt: /^.*@.*@.*$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  emailSymbols: /^[\w@.-]+$/,
  firstSpace: / +/i,
  getCvv: (length = 3) => new RegExp(`^[0-9]{${length}}$`),
  htmlTagBrackets: /<[^>]+>/g,
  isSymbolDigitOrPlus: /^[+0-9]$/,
  latinString: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[ a-zA-Z-]*)*\s?$/,
  limitDot: /\.{2}/,
  noDigits: /\D/g,
  noDigitsOrPlus: /[^+\d]/g,
  onlyDigits: /[0-9]/g,
  onlyLatinSymbols: /[a-zA-Z]/g,
  plusAndDigitsOrDigitsOrEmptyString: /^(\+?[\d\s()-]*)$/,
}
