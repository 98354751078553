import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import type { ComponentType, FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import type { NextApplicationsNamesType } from '@Types/applications/nextApps'
import { ApplicationNames } from '@constants/applications/applicationNames'
import type { TermsAndConditionsButtonsProps } from '@hstComponents/footer/TermsAndConditionsButtons'
import getAppConfig from '@services/config/config'

const buttonsComponents: Record<NextApplicationsNamesType, ComponentType<TermsAndConditionsButtonsProps>> = {
  [ApplicationNames.HST]: dynamic(() => import('@hstComponents/footer/TermsAndConditionsButtons')),
  [ApplicationNames.RN_CLASSIC]: dynamic(() => import('@rnComponents/footer/TermsAndConditionsButtons')),
  [ApplicationNames.RN_INTERIM]: dynamic(() => import('@rnComponents/footer/TermsAndConditionsButtons')),
  [ApplicationNames.RN_MODERN]: dynamic(() => import('@rnComponents/footer/TermsAndConditionsButtons')),
  [ApplicationNames.RN_NEW]: dynamic(() => import('@rnComponents/footer/TermsAndConditionsButtons')),
}

const { publicRuntimeConfig } = getAppConfig()

const TermsAndPolicy: FC = () => {
  const { t } = useTranslation('Footer')

  const Buttons = buttonsComponents[publicRuntimeConfig.site as NextApplicationsNamesType]

  return (
    <Wrapper>
      <Buttons privacyLabel={t('privacy')} termsLabel={t('terms')} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;

  ${p => p.theme.mediaQueries.mobile} {
    gap: 10px;

    button {
      opacity: 0.5;
    }
  }
`

export default memo(TermsAndPolicy)
